/**
 * Do not edit directly
 * Generated on Thu, 10 Oct 2024 11:19:56 GMT
 */

import { useMediaQuery } from '~/shared/hooks/useMediaQuery';

export const useBreakpointSmall320Up = () => useMediaQuery('(min-width: 320px)');

export const useBreakpointMedium768Up = () => useMediaQuery('(min-width: 768px)');

export const useBreakpointLarge1280Up = () => useMediaQuery('(min-width: 1280px)');

export const useBreakpointXLarge1920Up = () => useMediaQuery('(min-width: 1920px)');
